export default{
  name : 'NavbarComponent',
  data(){
      return{
        workbench_dropdown_toggle: false,
        menu_toggle:false,
      }
  },
  mounted(){
    this.menu_toggle =  this.$store.state.menu_expand
    this.menu_active_icon();
  },
  
  methods:{
    menu_active_icon(){
      if( document.getElementsByClassName('router-link-exact-active')[0]){
        var active_menu = document.getElementsByClassName('router-link-exact-active')[0].children[0].children;
        active_menu[0].hidden = true;
        active_menu[1].className = "mr_2_5rem";
        if(active_menu[2]){
          active_menu[2].className += " blue";
        }
        
        if(document.getElementsByClassName('router-link-exact-active')[0].closest('.dropdown-container') != null){
          var active_menu1 = document.getElementsByClassName('router-link-exact-active')[0].closest('.dropdown-container').previousElementSibling.children;
          active_menu[1].className = "mr-6";
          active_menu1[0].hidden = true;
          active_menu1[1].hidden = false;
          active_menu1[1].className = " mr_2_5rem";
          active_menu1[2].className += " blue";
        }
      }
    },
  }
}

import moment from 'moment';
export default {
    name: 'TopbarComponent',
    data(){
        return { 
            organiser_name : JSON.parse(sessionStorage.getItem('organization_details')).full_name      
        }    
    },
    methods:{

         async signout(){
            if(sessionStorage.getItem('agenda_details')){
    
                let meeting_details = JSON.parse(sessionStorage.getItem('meet_details'))
                let meeting_obj = {
                    "id": meeting_details.meeting._id,
                    "status": 'Draft',
                    "local_timezone": this.Constants.MEET_TIME_ZONE,
                    "meeting_end_time" : moment().format("YYYY-MM-DD HH:mm")
                }
    
               await this.$store.dispatch('CrudMeeting',[this.$store.state.api, meeting_obj]);
            }
            sessionStorage.clear();
            let path = "/"
            if (this.$route.path !== path){
                this.$router.push(path)
            } 
        }
    }
}